import * as React from "react";
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import Seo from "../components/seo/seo";
import HeaderMarkets from "../components/headers/header-markets";
import Help from "../components/global/help";
import Projects from "../components/global/projects";
import IconCode from "../assets/images/markets/code.svg";
import IconAi from "../assets/images/markets/ai.svg";
import IconAugmented from "../assets/images/markets/augmented.svg";
import IconInteraction from "../assets/images/markets/interaction.svg";
import PromiseAppEngIt from "../components/markets/promise-app-eng-it";
import HeaderBcg from "../assets/images/headers/marketing.png"


const Marketing = () => {
  const { t } = useTranslation()
  // Header Content
  const hTitle = `${t('markets.marketing')}`;
  const hShortText = `${t('marketsPages.marketing.shortText')}`;
  const hText = `${t('marketsPages.marketing.text')}`;
  const helpHeadline = `${t('marketsPages.helpHeadline')}`;

  // Promise Component
  const helpContent = [
    {
      title: `${t('marketsPages.marketing.helpContent.firstCard.title')}`,
      textOne: `${t('marketsPages.marketing.helpContent.firstCard.textOne')}`,
      textTwo: `${t('marketsPages.marketing.helpContent.firstCard.textTwo')}`,
      img: <IconCode />,
    },
    {
      title: `${t('marketsPages.marketing.helpContent.secondCard.title')}`,
      textOne: `${t('marketsPages.marketing.helpContent.secondCard.textOne')}`,
      textTwo: `${t('marketsPages.marketing.helpContent.secondCard.textTwo')}`,
      img: <IconAi />,
    },
    {
      title: `${t('marketsPages.marketing.helpContent.thirdCard.title')}`,
      textOne: `${t('marketsPages.marketing.helpContent.thirdCard.textOne')}`,
      textTwo: `${t('marketsPages.marketing.helpContent.thirdCard.textTwo')}`,
      img: <IconAugmented />,
    },
    {
      title: `${t('marketsPages.marketing.helpContent.fourthCard.title')}`,
      textOne: `${t('marketsPages.marketing.helpContent.fourthCard.textOne')}`,
      textTwo: `${t('marketsPages.marketing.helpContent.fourthCard.textTwo')}`,
      img: <IconInteraction />,
    },
  ];
  return (
    <Layout>
      <Seo title={hTitle} />
      <HeaderMarkets
        hTitle={hTitle}
        hShortText={hShortText}
        hText={hText}
        bkgImage={HeaderBcg}
        hNum={'25'}
      />
      <Help helpContent={helpContent} helpHeadline={helpHeadline} />
      <PromiseAppEngIt promiseContentItems={['app-dev', 'eng-team', "other-services"]} />
      <Projects />
    </Layout>
  );
};

export default Marketing;

export const query = graphql`
query($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`